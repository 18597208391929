/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import { v4 as uuidv4 } from 'uuid';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-webgpu';

import * as mpPose from '@mediapipe/pose';
import * as mpSegmentation from '@mediapipe/selfie_segmentation';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as tf from '@tensorflow/tfjs-core';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

import * as posedetection from '@tensorflow-models/pose-detection';
import * as bodySegmentation from '@tensorflow-models/body-segmentation';

import {Camera} from './camera';
import {RendererWebGPU} from './renderer_webgpu';
import {RendererCanvas2d} from './renderer_canvas2d';
import {setupDatGui} from './option_panel';
import {STATE} from './params';
import {setupStats} from './stats_panel';
import { setBackendAndEnvFlags, getRatio, getDistance, getMousePos } from './util';
import { dataTable } from './output';
import { dbConfig, measuresConfig } from "./config";

let detector, segmenter, camera, stats;
let startInferenceTime, numInferences = 0;
let inferenceTimeSum = 0, lastPanelUpdate = 0;
let rafId;
let renderer = null;
let rendererImage = null;
let renderOriginImage = null;
let useGpuRenderer = false;

let newPointPair = [];
let newPoints = [];
let originImageSrc = null;
let isBodySegmentation = true;
let clearFlag = false;
let image_poses = null;

async function createDetector() {
  switch (STATE.model) {
    case posedetection.SupportedModels.PoseNet:
      return posedetection.createDetector(STATE.model, {
        quantBytes: 4,
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: 500, height: 500},
        multiplier: 0.75
      });
    case posedetection.SupportedModels.BlazePose:
      const runtime = STATE.backend.split('-')[0];
      if (runtime === 'mediapipe') {
        return posedetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          solutionPath:
              `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
          enableSegmentation: true,
          smoothSegmentation: true
        });
      } else if (runtime === 'tfjs') {
        return posedetection.createDetector(
            STATE.model, {runtime, modelType: STATE.modelConfig.type, enableSegmentation: true, smoothSegmentation: true});
      }
    case posedetection.SupportedModels.MoveNet:
      let modelType;
      if (STATE.modelConfig.type == 'lightning') {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
      } else if (STATE.modelConfig.type == 'thunder') {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
      } else if (STATE.modelConfig.type == 'multipose') {
        modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
      }
      const modelConfig = {modelType};

      if (STATE.modelConfig.customModel !== '') {
        modelConfig.modelUrl = STATE.modelConfig.customModel;
      }
      if (STATE.modelConfig.type === 'multipose') {
        modelConfig.enableTracking = STATE.modelConfig.enableTracking;
      }
      return posedetection.createDetector(STATE.model, modelConfig);
    case bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation: {
        const runtime = STATE.backend.split('-')[0];
        if (runtime === 'mediapipe') {
          return bodySegmentation.createSegmenter(STATE.model, {
            runtime,
            modelType: STATE.modelConfig.type,
            solutionPath:
                `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${
                  mpSegmentation.VERSION}`
          });
        } else if (runtime === 'tfjs') {
          return bodySegmentation.createSegmenter(STATE.model, {
            runtime,
            modelType: STATE.modelConfig.type,
          });
        }
    }

  }
}

async function createSegmentation() {
  const model = bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation; // or 'BodyPix'
  const segmenterConfig = {
    runtime: 'mediapipe', // or 'tfjs'
    modelType: 'general', // or 'landscape'
    solutionPath:
      `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${mpSegmentation.VERSION}`
  };
  return bodySegmentation.createSegmenter(model, segmenterConfig);
}

async function checkGuiUpdate() {
  if (STATE.isTargetFPSChanged || STATE.isSizeOptionChanged) {
    camera = await Camera.setupCamera(STATE.camera);
    STATE.isTargetFPSChanged = false;
    STATE.isSizeOptionChanged = false;
  }

  if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
    STATE.isModelChanged = true;

    window.cancelAnimationFrame(rafId);

    if (detector != null) {
      detector.dispose();
    }

    if (STATE.isFlagChanged || STATE.isBackendChanged) {
      await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    }

    try {
      detector = await createDetector(STATE.model);
    } catch (error) {
      detector = null;
      alert(error);
    }

    STATE.isFlagChanged = false;
    STATE.isBackendChanged = false;
    STATE.isModelChanged = false;
  }
}

function beginEstimatePosesStats() {
  startInferenceTime = (performance || Date).now();
}

function endEstimatePosesStats() {
  const endInferenceTime = (performance || Date).now();
  inferenceTimeSum += endInferenceTime - startInferenceTime;
  ++numInferences;

  const panelUpdateMilliseconds = 1000;
  if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
    const averageInferenceTime = inferenceTimeSum / numInferences;
    inferenceTimeSum = 0;
    numInferences = 0;
    stats.customFpsPanel.update(
        1000.0 / averageInferenceTime, 120 /* maxValue */);
    lastPanelUpdate = endInferenceTime;
  }
}

async function renderResult() {
  if (camera.video.readyState < 2) {
    await new Promise((resolve) => {
      camera.video.onloadeddata = () => {
        resolve(video);
      };
    });
  }
  let poses = null;
  let canvasInfo = null;
  let people = null;
  // Detector can be null if initialization failed (for example when loading
  // from a URL that does not exist).
  if (detector != null) {
    // FPS only counts the time it takes to finish estimatePoses.
    beginEstimatePosesStats();

    if (useGpuRenderer && STATE.model !== 'PoseNet') {
      throw new Error('Only PoseNet supports GPU renderer!');
    }
    // Detectors can throw errors, for example when using custom URLs that
    // contain a model that doesn't provide the expected output.
    try {
      if (useGpuRenderer) {
        const [posesTemp, canvasInfoTemp] = await detector.estimatePosesGPU(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false},
            true);
        poses = posesTemp;
        canvasInfo = canvasInfoTemp;
      } else {
        poses = await detector.estimatePoses(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
      }
      

    } catch (error) {
      detector.dispose();
      detector = null;
      alert(error);
    }

    // endEstimatePosesStats();
    

    if (segmenter != null) {
      const foregroundThreshold = 0.5;
      const people = await segmenter.segmentPeople(camera.video, {
        flipHorizontal: false,
        multiSegmentation: false,
        segmentBodyParts: true,
        segmentationThreshold: foregroundThreshold
      });
      const foregroundColor = {r: 0, g: 0, b: 0, a: 0};
      const backgroundColor = {r: 0, g: 0, b: 0, a: 255};
      const drawContour = true;
      
      const backgroundDarkeningMask = await bodySegmentation.toBinaryMask(people, foregroundColor, backgroundColor, drawContour, foregroundThreshold);

      const opacity = 0.7;
      const maskBlurAmount = 3; // Number of pixels to blur by.
      const flipHorizontal = false;
      const canvas = document.getElementById('output');

      await bodySegmentation.drawMask(canvas, camera.video, backgroundDarkeningMask, opacity, maskBlurAmount, flipHorizontal);
    }
  }
  // dataTable(poses)
  if (!isBodySegmentation) {
    const rendererParams = useGpuRenderer ?
    [camera.video, poses, canvasInfo, STATE.modelConfig.scoreThreshold] :
    [camera.video, poses, STATE.isModelChanged];
    renderer.draw(rendererParams);
  } else {
    const canvas = document.getElementById('output');
    const rendererParams = useGpuRenderer ?
        [canvas, poses, canvasInfo, STATE.modelConfig.scoreThreshold] :
        [canvas, poses, STATE.isModelChanged];
    renderer.draw(rendererParams);
  }
  
}

async function renderPrediction() {
  await checkGuiUpdate();

  if (!STATE.isModelChanged) {
    await renderResult();
  }

  rafId = requestAnimationFrame(renderPrediction);
};

async function imagePoseDetect() {
  const canvasImage = document.getElementById('poseCanvas');
  const image = document.getElementById("capturedImage");
  canvasImage.width = image.width;
  canvasImage.height = image.height;
  rendererImage = new RendererCanvas2d(canvasImage)
  renderOriginImage = new RendererCanvas2d(canvasImage)
  
  image_poses = null;
  image_poses = await detector.estimatePoses(image, {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});

  canvasImage.onmousedown = function(e) {
    const sideElement = document.getElementById("sideBody");
    const ratio = getRatio(image_poses[0].keypoints, image_poses[0].keypoints3D, sideElement.checked);
    var pos = getMousePos(this, e), /// provide this canvas and event
    x = pos.x,
    y = pos.y;
    rendererImage.drawKeypoint(pos);
    if(newPointPair.length < 3) {
      newPointPair.push(pos);
      if (newPointPair.length == 2) {
        rendererImage.drawNewSkeleton(newPointPair, getDistance(newPointPair, ratio));
        newPoints.push({pointPair: newPointPair, distance: getDistance(newPointPair, ratio)});
        var table = document.getElementById("table-container");
        var newRow = table.insertRow(1);
        var cell1 = newRow.insertCell(0);
        var cell2 = newRow.insertCell(1);
        var cell3 = newRow.insertCell(2);
        cell1.innerHTML = "New Measurement";
        cell2.innerHTML = getDistance(newPointPair, ratio);
        cell3.innerHTML = "99";
        newPointPair = [];
      }
    }
  }
  dataTable(image_poses, "table-container");
  dataTable(image_poses, "autoMeasuresTable");
  rendererImage.draw([image, null, STATE.isModelChanged]);
}




const savebtn = document.getElementById("saveMeasureBtn");
savebtn.addEventListener('click', function() {
  document.getElementById('saveMeasureBtn').disabled = true;
  document.getElementById('loader').style.display = 'inline-block';
  
  const img = document.getElementById('capturedImage');
  // Function to convert image to Base64
  function convertImageToBase64(image, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0, image.width, image.height);

    // Get Base64 data
    const base64Data = canvas.toDataURL('image/png');

    callback(base64Data);
  }

  function sendDataToRestDB(base64Image) {
    const table = document.getElementById("autoMeasuresTable");
    for (var i = 1; i < table.rows.length; i++) {
      var cells = table.rows[i].cells;
      measuresConfig[i-1].distance = cells[1].innerHTML;
      measuresConfig[i-1].confidence = cells[2].innerHTML;
    }
    const id = uuidv4();
    Date.prototype.today = function () { 
      return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes()
    }
    var currentdate = new Date(); 
    // var datetime = currentdate.today() + " " + currentdate.timeNow();
    const sideElement = document.getElementById("sideBody");
    fetch("https://mobilitymeasure-bb14.restdb.io/rest/measures", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': "65b2416673078250fd6708b5"
      },
      body: JSON.stringify({
        id: id,
        image: base64Image,
        measurements_table: measuresConfig,
        image_poses: image_poses,
        createdAt: currentdate,
        side_action: sideElement.checked
      })
    })
      .then(response => {
        if (response.ok) {
          document.getElementById('saveMeasureBtn').disabled = false;
          document.getElementById('loader').style.display = 'none';
          console.log('Item created successfully!');
          alert("Item created successfully");
        } else {
          console.error('Error creating item');
          alert("Error creating item");
        }
      })
      .catch(error => {
        console.error('Error:', error);
    });
  }

  convertImageToBase64(img, sendDataToRestDB)
})

async function app() {
  // Gui content will change depending on which model is in the query string.

  // let urlParams = new URLSearchParams(window.location.search);
  // if (!urlParams.has('model')) {
  //   alert('Cannot find model in the query string.');
  //   return;
  // }

  await setupDatGui("urlParams");

  // stats = setupStats();
  const isWebGPU = STATE.backend === 'tfjs-webgpu';
  // const importVideo = (urlParams.get('importVideo') === 'true') && isWebGPU;

  camera = await Camera.setup(STATE.camera);

  await setBackendAndEnvFlags(STATE.flags, STATE.backend);
  await tf.ready();
  detector = await createDetector();
  // if (isBodySegmentation) {
    segmenter = await createSegmentation();
  // }
  const canvas = document.getElementById('output');
  canvas.width = camera.video.width;
  canvas.height = camera.video.height;
  // useGpuRenderer = (urlParams.get('gpuRenderer') === 'true') && isWebGPU;
  if (useGpuRenderer) {
    renderer = new RendererWebGPU(canvas, importVideo);
  } else {
    renderer = new RendererCanvas2d(canvas);
  }

  document.getElementById('segmentationButton').addEventListener('click', async function () {    
    isBodySegmentation = !isBodySegmentation;
    const buttonName = isBodySegmentation ? "Disable Body Segmentation" : "Enable Body Segmentation";
    document.getElementById("segmentationButton").innerHTML = buttonName;
    detector = null;
    app();
    if (useGpuRenderer) {
      renderer.dispose();
    }
  }); 

  // document.getElementById('clearButton').addEventListener('click', function () {
  //   clearFlag = true;
  //   capturedImage.src = originImageSrc;
  //   const table = document.getElementById("table-container");
  //   if (newPoints.length > 0) {
  //     for(let i = 0; i < newPoints.length; i++) {
  //       table.deleteRow(1);
  //     }
  //     newPoints = [];
  //   }
  // }); 

  document.getElementById('captureButton').addEventListener('click', function () {
    clearFlag = false;
    var canvas = document.getElementById('output');
    var capturedImage = document.getElementById('capturedImage');

    // Display the captured image
    originImageSrc = canvas.toDataURL('image/png');
    capturedImage.src = canvas.toDataURL('image/png');
  });

  const capturedImage = document.getElementById('capturedImage');
  capturedImage.onload = function() {
    if(!clearFlag) {
      imagePoseDetect();
    } else {
      const image = document.getElementById("capturedImage");
      renderOriginImage.draw([image, null, STATE.isModelChanged]);
    }
  }

  renderPrediction();
};

app();

if (useGpuRenderer) {
  renderer.dispose();
}
