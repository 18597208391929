export function dataTable(output, tableId) {
  if (output && output[0]?.keypoints3D) {
    const data = [
      { name: 'Shoulder', distance: getDistance(output[0]["keypoints3D"][11], output[0]["keypoints3D"][12]) },
    ];

    const table = document.getElementById(tableId);
    const tbody = table.getElementsByTagName("tbody")[0];
    const row0 = tbody.getElementsByTagName("tr")[0];
    const tdToUpdate01 = row0.getElementsByTagName("td")[1];
    const tdToUpdate02 = row0.getElementsByTagName("td")[2];
    tdToUpdate02.textContent = parseInt(output[0]["keypoints3D"][12].score * 100);
    if (output[0]["keypoints3D"][11].score > 0.8 && output[0]["keypoints3D"][12].score > 0.8)
      tdToUpdate01.textContent = getDistance(output[0]["keypoints3D"][11], output[0]["keypoints3D"][12])

    const row1 = tbody.getElementsByTagName("tr")[1];
    const tdToUpdate11 = row1.getElementsByTagName("td")[1];
    const tdToUpdate12 = row1.getElementsByTagName("td")[2];
    tdToUpdate12.textContent = parseInt(output[0]["keypoints3D"][24].score * 100);
    if (output[0]["keypoints3D"][23].score > 0.8 && output[0]["keypoints3D"][24].score > 0.8)
      tdToUpdate11.textContent = getDistance(output[0]["keypoints3D"][23], output[0]["keypoints3D"][24])

    const row2 = tbody.getElementsByTagName("tr")[2];
    const tdToUpdate21 = row2.getElementsByTagName("td")[1];
    const tdToUpdate22 = row2.getElementsByTagName("td")[2];
    tdToUpdate22.textContent = parseInt(output[0]["keypoints3D"][28].score * 100);
    if (output[0]["keypoints3D"][5].score > 0.8 && output[0]["keypoints3D"][28].score > 0.8)
      tdToUpdate21.textContent = getDistance(output[0]["keypoints3D"][5], output[0]["keypoints3D"][28])

    const row3 = tbody.getElementsByTagName("tr")[3];
    const tdToUpdate31 = row3.getElementsByTagName("td")[1];
    const tdToUpdate32 = row3.getElementsByTagName("td")[2];
    tdToUpdate32.textContent = parseInt(output[0]["keypoints3D"][27].score * 100);
    if (output[0]["keypoints3D"][2].score > 0.8 && output[0]["keypoints3D"][27].score > 0.8)
      tdToUpdate31.textContent = getDistance(output[0]["keypoints3D"][2], output[0]["keypoints3D"][27])

    const row4 = tbody.getElementsByTagName("tr")[4];
    const tdToUpdate41 = row4.getElementsByTagName("td")[1];
    const tdToUpdate42 = row4.getElementsByTagName("td")[2];
    tdToUpdate42.textContent = parseInt(output[0]["keypoints3D"][26].score * 100);
    if (output[0]["keypoints3D"][26].score > 0.8 && output[0]["keypoints3D"][28].score > 0.8)
      tdToUpdate41.textContent = getDistance(output[0]["keypoints3D"][26], output[0]["keypoints3D"][28])

    const row5 = tbody.getElementsByTagName("tr")[5];
    const tdToUpdate51 = row5.getElementsByTagName("td")[1];
    const tdToUpdate52 = row5.getElementsByTagName("td")[2];
    tdToUpdate52.textContent = parseInt(output[0]["keypoints3D"][27].score * 100);
    if (output[0]["keypoints3D"][25].score > 0.8 && output[0]["keypoints3D"][27].score > 0.8)
      tdToUpdate51.textContent = getDistance(output[0]["keypoints3D"][25], output[0]["keypoints3D"][27])

    const row6 = tbody.getElementsByTagName("tr")[6];
    const tdToUpdate61 = row6.getElementsByTagName("td")[1];
    const tdToUpdate62 = row6.getElementsByTagName("td")[2];
    tdToUpdate62.textContent = parseInt(output[0]["keypoints3D"][24].score * 100);
    if (output[0]["keypoints3D"][24].score > 0.8 && output[0]["keypoints3D"][26].score > 0.8)
      tdToUpdate61.textContent = getDistance(output[0]["keypoints3D"][24], output[0]["keypoints3D"][26])

    const row7 = tbody.getElementsByTagName("tr")[7];
    const tdToUpdate71 = row7.getElementsByTagName("td")[1];
    const tdToUpdate72 = row7.getElementsByTagName("td")[2];
    tdToUpdate72.textContent = parseInt(output[0]["keypoints3D"][23].score * 100);
    if (output[0]["keypoints3D"][23].score > 0.8 && output[0]["keypoints3D"][25].score > 0.8)
      tdToUpdate71.textContent = getDistance(output[0]["keypoints3D"][23], output[0]["keypoints3D"][25])

    const row8 = tbody.getElementsByTagName("tr")[8];
    const tdToUpdate81 = row8.getElementsByTagName("td")[1];
    const tdToUpdate82 = row8.getElementsByTagName("td")[2];
    tdToUpdate82.textContent = parseInt(output[0]["keypoints3D"][12].score * 100);
    if (output[0]["keypoints3D"][12].score > 0.8 && output[0]["keypoints3D"][24].score > 0.8)
      tdToUpdate81.textContent = getDistance(output[0]["keypoints3D"][12], output[0]["keypoints3D"][24])

    const row9 = tbody.getElementsByTagName("tr")[9];
    const tdToUpdate91 = row9.getElementsByTagName("td")[1];
    const tdToUpdate92 = row9.getElementsByTagName("td")[2];
    tdToUpdate92.textContent = parseInt(output[0]["keypoints3D"][11].score * 100);
    if (output[0]["keypoints3D"][11].score > 0.8 && output[0]["keypoints3D"][23].score > 0.8)
      tdToUpdate91.textContent = getDistance(output[0]["keypoints3D"][11], output[0]["keypoints3D"][23])

    const row10 = tbody.getElementsByTagName("tr")[10];
    const tdToUpdate101 = row10.getElementsByTagName("td")[1];
    const tdToUpdate102 = row10.getElementsByTagName("td")[2];
    tdToUpdate102.textContent = parseInt(output[0]["keypoints3D"][5].score * 100);
    if (output[0]["keypoints3D"][5].score > 0.8 && output[0]["keypoints3D"][24].score > 0.8)
      tdToUpdate101.textContent = getDistance(output[0]["keypoints3D"][5], output[0]["keypoints3D"][24])

    const row11 = tbody.getElementsByTagName("tr")[11];
    const tdToUpdate111 = row11.getElementsByTagName("td")[1];
    const tdToUpdate112 = row11.getElementsByTagName("td")[2];
    tdToUpdate112.textContent = parseInt(output[0]["keypoints3D"][2].score * 100);
    if (output[0]["keypoints3D"][2].score > 0.8 && output[0]["keypoints3D"][23].score > 0.8)
      tdToUpdate111.textContent = getDistance(output[0]["keypoints3D"][2], output[0]["keypoints3D"][23])


  }
}

function getDistance(start, end) {
  var a = end.x - start.x
  var b = end.y - start.y
  var c = end.z - start.z

  return (Math.sqrt(a * a + b * b + c * c) * 100).toFixed(2) + " cm"
}