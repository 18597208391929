export const dbConfig = {
    databaseId : "mobilitymeasure-bb14",
    apiKey : "65b2416673078250fd6708b5",
    apiUrl : `https://mobilitymeasure-bb14.restdb.io/rest/measures`
};

export const measuresConfig = [
    { name: "shoulderWidth", label: "Shoulder Width", distance: 0, confidence: 0 },
    { name: "hipWidth", label: "Hip Width", distance: 0, confidence: 0 },
    { name: "leftHeight", label: "Height (Left)", distance: 0, confidence: 0 },
    { name: "rightHeight", label: "Height (Right)", distance: 0, confidence: 0 },
    { name: "leftFootToKnee", label:"Foot to Knee (Left)", distance: 0, confidence: 0 },
    { name: "rightFootToKnee", label: "Foot to Knee (Right)", distance: 0, confidence: 0 },
    { name: "leftKneeToHip", label: "Knee to Hip (Left)", distance: 0, confidence: 0 },
    { name: "rightKneeToHip", label: "Knee to Hip (Right)", distance: 0, confidence: 0 },
    { name: "leftHipToShoulder", label: "Hip to Shoudler (Left)", distance: 0, confidence: 0 },
    { name: "rightHipToShoulder", label: "Hip to Shoudler (Right)", distance: 0, confidence: 0 },
    { name: "leftHipToHead", label: "Hip to Head (Left)",  distance: 0, confidence: 0 },
    { name: "rightHipToHead", label: "Hip to Head (Right)", distance: 0, confidence: 0 },
]