/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import * as tf from '@tensorflow/tfjs-core';
import {showBackendConfigs} from './option_panel';
import {STATE, TUNABLE_FLAG_VALUE_RANGE_MAP} from './params';
import { dbConfig } from "./config";

export function isiOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
  return isAndroid() || isiOS();
}

/**
 * Reset the target backend.
 *
 * @param backendName The name of the backend to be reset.
 */
async function resetBackend(backendName) {
  const ENGINE = tf.engine();
  if (!(backendName in ENGINE.registryFactory)) {
    if(backendName === 'webgpu') {
      alert('webgpu backend is not registered. Your browser may not support WebGPU yet. To test this backend, please use a supported browser, e.g. Chrome canary with --enable-unsafe-webgpu flag');
      STATE.backend = !!STATE.lastTFJSBackend ? STATE.lastTFJSBackend : 'tfjs-webgl';
      showBackendConfigs();
      return;
    } else {
      throw new Error(`${backendName} backend is not registered.`);
    }
  }

  if (backendName in ENGINE.registry) {
    const backendFactory = tf.findBackendFactory(backendName);
    tf.removeBackend(backendName);
    tf.registerBackend(backendName, backendFactory);
  }

  await tf.setBackend(backendName);
  STATE.lastTFJSBackend = `tfjs-${backendName}`;
}

/**
 * Set environment flags.
 *
 * This is a wrapper function of `tf.env().setFlags()` to constrain users to
 * only set tunable flags (the keys of `TUNABLE_FLAG_TYPE_MAP`).
 *
 * ```js
 * const flagConfig = {
 *        WEBGL_PACK: false,
 *      };
 * await setEnvFlags(flagConfig);
 *
 * console.log(tf.env().getBool('WEBGL_PACK')); // false
 * console.log(tf.env().getBool('WEBGL_PACK_BINARY_OPERATIONS')); // false
 * ```
 *
 * @param flagConfig An object to store flag-value pairs.
 */
export async function setBackendAndEnvFlags(flagConfig, backend) {
  if (flagConfig == null) {
    return;
  } else if (typeof flagConfig !== 'object') {
    throw new Error(
        `An object is expected, while a(n) ${typeof flagConfig} is found.`);
  }

  // Check the validation of flags and values.
  for (const flag in flagConfig) {
    // TODO: check whether flag can be set as flagConfig[flag].
    if (!(flag in TUNABLE_FLAG_VALUE_RANGE_MAP)) {
      throw new Error(`${flag} is not a tunable or valid environment flag.`);
    }
    if (TUNABLE_FLAG_VALUE_RANGE_MAP[flag].indexOf(flagConfig[flag]) === -1) {
      throw new Error(
          `${flag} value is expected to be in the range [${
              TUNABLE_FLAG_VALUE_RANGE_MAP[flag]}], while ${flagConfig[flag]}` +
          ' is found.');
    }
  }

  tf.env().setFlags(flagConfig);

  const [runtime, $backend] = backend.split('-');

  if (runtime === 'tfjs') {
    await resetBackend($backend);
  }
}

function getBestRatio(arrConfidence, arrRatio) {
  if (arrConfidence.length === 0) {
      return -1;
  }

  var maxConfidence = arrConfidence[0];
  var bestRatio = arrRatio[0];

  for (var i = 1; i < arrConfidence.length; i++) {
      if (arrConfidence[i] > maxConfidence && arrRatio[i] > 0) {
          bestRatio = arrRatio[i];
          maxConfidence = arrConfidence[i];
      }
  }

  return bestRatio;
}

export function getRatio(pointPxcel, point3D, side) {
  if (side) {
    // foot to knee left
    var a = pointPxcel[28].x - pointPxcel[26].x
    var b = pointPxcel[28].y - pointPxcel[26].y
    var c = pointPxcel[28].z - pointPxcel[26].z
    var pixcelDistance = Math.sqrt(a * a + b * b + c * c);
    a = point3D[28].x - point3D[26].x
    b = point3D[28].y - point3D[26].y
    c = point3D[28].z - point3D[26].z
    var distance3D = Math.sqrt(a * a + b * b + c * c) * 100;
    var leftFootToKneeRatio = distance3D/pixcelDistance;
    // foot to knee right
    var a = pointPxcel[27].x - pointPxcel[25].x
    var b = pointPxcel[27].y - pointPxcel[25].y
    var c = pointPxcel[27].z - pointPxcel[25].z
    var pixcelDistance = Math.sqrt(a * a + b * b + c * c);
    a = point3D[27].x - point3D[25].x
    b = point3D[27].y - point3D[25].y
    c = point3D[27].z - point3D[25].z
    var distance3D = Math.sqrt(a * a + b * b + c * c) * 100;
    var rightFootToKneeRatio = distance3D/pixcelDistance;

    // knee to hip left
    var a = pointPxcel[26].x - pointPxcel[24].x
    var b = pointPxcel[26].y - pointPxcel[24].y
    var c = pointPxcel[26].z - pointPxcel[24].z
    var pixcelDistance = Math.sqrt(a * a + b * b + c * c);
    a = point3D[26].x - point3D[24].x
    b = point3D[26].y - point3D[24].y
    c = point3D[26].z - point3D[24].z
    var distance3D = Math.sqrt(a * a + b * b + c * c) * 100;
    var leftRatio = distance3D/pixcelDistance;
    // knee to hip right
    a = pointPxcel[25].x - pointPxcel[23].x
    b = pointPxcel[25].y - pointPxcel[23].y
    c = pointPxcel[25].z - pointPxcel[23].z
    pixcelDistance = Math.sqrt(a * a + b * b + c * c);
    a = point3D[25].x - point3D[23].x
    b = point3D[25].y - point3D[23].y
    c = point3D[25].z - point3D[23].z
    distance3D = Math.sqrt(a * a + b * b + c * c) * 100;
    var rightRatio = distance3D/pixcelDistance;

    const ratioArray = [leftFootToKneeRatio, rightFootToKneeRatio, leftRatio, rightRatio];
    const confidenceArray = [point3D[28].score, point3D[27].score, point3D[24].score, point3D[23].score];
    var ratio = getBestRatio(confidenceArray, ratioArray);
    
    return ratio;
  } else {
    // shoulder width ratio
    var a = pointPxcel[12].x - pointPxcel[11].x
    var b = pointPxcel[12].y - pointPxcel[11].y
    var c = pointPxcel[12].z - pointPxcel[11].z
    var pixcelDistance = Math.sqrt(a * a + b * b + c * c);
    a = point3D[12].x - point3D[11].x
    b = point3D[12].y - point3D[11].y
    c = point3D[12].z - point3D[11].z
    var distance3D = Math.sqrt(a * a + b * b + c * c) * 100;
    var shoulderRatio = distance3D/pixcelDistance;
    // hip width ratio
    a = pointPxcel[24].x - pointPxcel[23].x
    b = pointPxcel[24].y - pointPxcel[23].y
    c = pointPxcel[24].z - pointPxcel[23].z
    pixcelDistance = Math.sqrt(a * a + b * b + c * c);
    a = point3D[24].x - point3D[23].x
    b = point3D[24].y - point3D[23].y
    c = point3D[24].z - point3D[23].z
    distance3D = Math.sqrt(a * a + b * b + c * c) * 100;
    var hipRatio = distance3D/pixcelDistance;
    var ratio = shoulderRatio;
    if (point3D[12].score > 0.8 && point3D[24].score > 0.8){
      ratio = (shoulderRatio + hipRatio) / 2;
    } else {
      if (point3D[24].score > 0.8) {
        ratio = hipRatio;
      }
    }
    return ratio;
  }
  
}

export function getDistance(newPointPair, ratio) {
  var a = newPointPair[1].x - newPointPair[0].x
  var b = newPointPair[1].y - newPointPair[0].y
  // var c = newPointPair[1].z - newPointPair[0].z

  return (Math.sqrt(a * a + b * b) * ratio).toFixed(2) + " cm"
}

export function getMousePos(canvas, e) {

  /// getBoundingClientRect is supported in most browsers and gives you
  /// the absolute geometry of an element
  var rect = canvas.getBoundingClientRect();

  /// as mouse event coords are relative to document you need to
  /// subtract the element's left and top position:
  return {x: e.clientX - rect.left, y: e.clientY - rect.top};
}


// Function to pad single-digit numbers with a leading zero
function padZero(number) {
  return number < 10 ? `0${number}` : number;
}

export function convertDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formattedDate = `${year}-${padZero(month)}-${padZero(day)} ${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  return formattedDate;
}


export function deleteTableRow(itemId) {
  const row = document.querySelector(`tr[id='${itemId}']`);
  if (row) {
    // Delete the row visually
    row.remove();
  }
}

export async function getItemFromRestDB(itemId) {
  const getUrl = `${dbConfig.apiUrl}/${itemId}`;
  console.log("getUrl:", getUrl);
  try {
    const response = await fetch(getUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': dbConfig.apiKey,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
    console.log('Item retrieved from RestDB:', data);
  } catch (error) {
    console.error(`Error getting item with ID ${itemId} from RestDB:`, error);
  }
}
